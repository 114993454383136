import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UserService} from '../../services/user/user.service';
import {GetUsers} from '../../services/user/GetUsers';
import {StringUtil} from '../../StringUtil';
import {AbstractInputDirective} from '../AbstractInputDirective';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'digires-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.scss']
})
export class UserInputComponent extends AbstractInputDirective implements OnInit, OnDestroy {
  readonly destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;

  @Output() onSelected = new EventEmitter<GetUsers>();
  @Input() multiSelect;
  allUsers: GetUsers[];
  filteredUsers: GetUsers[];

  constructor(private userService: UserService) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.userService
      .observeUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(userList => {
        this.allUsers = userList.sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.filteredUsers = userList;
        this.initializeSelectedUser();
      });
    this.userService.fetchAllUsers();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private initializeSelectedUser() {
    const value = this.getValue();
    if (value && (!value?.id || !value?.fullName)) {
      const maybeId = value + '';
      const user = this.allUsers.find(user => user.id === maybeId);
      if (user) {
        this.patch(user);
      }
    }
  }

  onFilterUsers(event: any): void {
    const value = event.target.value;
    if (!value) {
      this.onUserSelected(null);
      return;
    }
    const lowerCaseVal: string = value.toLowerCase();
    this.filteredUsers = this.allUsers.filter(user => {
      return StringUtil.filteredIncludes(user.fullName, lowerCaseVal);
    });

    const foundOpt = this.filteredUsers.find(user => {
      return user.fullName.toLowerCase() === lowerCaseVal;
    });
    if (foundOpt) {
      this.onUserSelected(foundOpt);
    }
  }

  onUserSelected(value: GetUsers): void {
    this.filteredUsers = this.allUsers;
    this.patch(value);
    this.onSelected.emit(value);
  }

  getUserErrorMessage(): string {
    if (this.formGroup && this.formGroup.invalid) {
      const user = this.getValue();
      if (!user || !user.accountName) {
        return 'Entered User is not a DigiRES User.';
      }
    }
    return null;
  }

  getUserName(user: GetUsers): string {
    return user?.fullName;
  }

  requestFocusForInput() {
    try {
      this.inputElement.nativeElement.focus();
    } catch (e) {
      console.error(e);
    }
  }

}
