<form [formGroup]="deepFormGroup">
  <digires-state-indicator [formCtrlName]="fullFormCtrlName" [readOnly]="readOnly" [readOnlyReason]="readOnlyReason" [finModel]="finModel"></digires-state-indicator>
  <mat-form-field [ngClass]="{'changed':isDirty()}">
    <ng-container *ngIf="!multiSelect">
      <label >
        <input #input
          matInput type="text"
          formControlName="{{formCtrlName}}"
          placeholder="{{placeholder}}"
          [required]="required && !readOnly"
          [readonly]="readOnly"
          [matAutocomplete]='autocomplete'
          (change)="onInputChanged()"
          (input)="onFilterUsers($event)"
        >
      </label>

      <mat-autocomplete
        #autocomplete="matAutocomplete"
        [displayWith]="getUserName.bind(this)"
        (optionSelected)="onUserSelected($event.option.value)"
      >
        <mat-option *ngFor="let value of filteredUsers" [value]="value">
          {{value.fullName}}
        </mat-option>
      </mat-autocomplete>
    </ng-container>
    <ng-container *ngIf="multiSelect">
      <mat-select
        formControlName="{{formCtrlName}}"
        placeholder="{{placeholder}}"
        disableOptionCentering
        panelClass="mat-dropdown-panel-override"
        [required]="required && !readOnly"
        [multiple]="multiSelect"
        [disabled]="readOnly"
      >
        <mat-option *ngFor="let value of filteredUsers" [value]="value">
          {{value.fullName}}
        </mat-option>
      </mat-select>
    </ng-container>

    <mat-error>
      <div *ngIf="error">{{error}}</div>
      <ng-container *ngFor="let err of errors">
        <div *ngIf="deepFormGroup.controls[formCtrlName].hasError(err.id)">{{err.message}}</div>
      </ng-container>
    </mat-error>
  </mat-form-field>
  <mat-icon *ngIf="!!tooltip" class="tooltip-icon" matTooltipPosition="above" matTooltip="{{tooltip}}" svgIcon="tooltip"></mat-icon>
  <mat-icon *ngIf="!!finModel" class="warning-triangle" [class.transparent]="!finModel.hasUpload || !finModel.isReadable(fullFormCtrlName) || finModel.isRead(fullFormCtrlName)"
            matTooltip="Note: The value in this field was not populated by the most recent upload of the financial model">
    warning
  </mat-icon>
</form>
